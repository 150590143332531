import i18n from "@/plugins/i18n";

export default {
  DashboardMode: {
    DEFAULT: 0,
    EDIT: 1,
  },

  ModuleSize: {
    XS: 2,
    SM: 4,
    MD: 6,
    LG: 12,
  },

  SizeForType: [
    // CHART
    {
      sm: [{ name: i18n.t("module.enum.size.lg"), value: 12 }],
      md: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
    },
    //GAUGE
    {
      sm: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
      md: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.xs"), value: 2 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
    },
    // TEXT
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.xs"), value: 2 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.xs"), value: 2 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
    },
    // IMAGE
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
      md: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
    },
    // MAP
    {
      sm: [{ name: i18n.t("module.enum.size.lg"), value: 12 }],
      md: [{ name: i18n.t("module.enum.size.lg"), value: 12 }],
    },
    // COMPANYINFO
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },

    // IFRAME
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
    },

    // WEATHER
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },

    // SINGLE value
    {
      sm: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.xs"), value: 2 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
      md: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.xs"), value: 2 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
    },
    // RAWTABLE value
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },
    // MultiChart
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
      md: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
    },
    // ImageMap
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },
    // Button
    {
      sm: [{ name: i18n.t("module.enum.size.xs"), value: 2 }],
      md: [{ name: i18n.t("module.enum.size.xs"), value: 2 }],
    },
    // InfoCard
    {
      sm: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
      md: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.xs"), value: 2 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },
    // TrendChart
    {
      sm: [{ name: i18n.t("module.enum.size.lg"), value: 12 }],
      md: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
      ],
    },
    // SensorOverviewList
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },
    // TiltChart
    {
      sm: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
      md: [
        { name: i18n.t("module.enum.size.sm"), value: 4 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
      ],
    },
    // FillIndicator
    {
      sm: [
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
      md: [
        { name: i18n.t("module.enum.size.md"), value: 6 },
        { name: i18n.t("module.enum.size.lg"), value: 12 },
        { name: i18n.t("module.enum.size.sm"), value: 4 },
      ],
    },
  ],
};
