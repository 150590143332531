<template>
  <div>
    <v-btn icon color="primary" @click="createDialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="createDialog" max-width="500px">
      <v-card>
        <v-card-title>{{ $t("camOnline.createDialogTitle") }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('camOnline.fields.url')"
            prepend-inner-icon="mdi-domain"
            v-model="createUrl"
          />
        </v-card-text>
        <v-card-text>
          <v-text-field
            :label="$t('camOnline.fields.accessToken')"
            prepend-inner-icon="mdi-security"
            v-model="createAccessToken"
          />
        </v-card-text>
        <v-card-text>
          <v-switch
            :label="$t('camOnline.fields.isGlobal')"
            v-model="createIsGlobal"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createDialog = false" color="grey" text>{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn @click="save" color="primary">{{ $t("common.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CamOnlineServerCreateDialog",
  data() {
    return {
      createDialog: false,

      createUrl: "",
      createAccessToken: "",
      createIsGlobal: false,
    };
  },

  methods: {
    ...mapActions("camOnline", ["createServer"]),

    async save() {
      var payload = {
        url: this.createUrl,
        accessToken: this.createAccessToken,
        isGlobal: this.isGlobal,
      };

      await this.createServer(payload);
      this.createDialog = false;
    },
  },
};
</script>