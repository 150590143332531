<template>
  <v-card>
    <v-card-text>
      <editor-menu-bar
        :editor="editor"
        v-slot="{ commands, isActive, getMarkAttrs }"
      >
        <div class="menubar">
          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
          >
            <v-icon> mdi-format-bold </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
          >
            <v-icon> mdi-format-italic </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
          >
            <v-icon> mdi-format-strikethrough </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
          >
            <v-icon> mdi-format-underline </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <v-icon> mdi-format-paragraph </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            <v-icon> mdi-format-header-1 </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            <v-icon> mdi-format-header-2 </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <v-icon> mdi-format-header-3 </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <v-icon> mdi-format-list-bulleted </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <v-icon> mdi-format-list-numbered </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.link() }"
            @click="showLinkMenu(getMarkAttrs('link'))"
          >
            <v-icon> mdi-link </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.code_block() }"
            @click="commands.code_block"
          >
            <v-icon> mdi-console </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            :class="{ 'is-active': isActive.blockquote() }"
            @click="commands.blockquote"
          >
            <v-icon> mdi-format-quote-close </v-icon>
          </button>

          <button class="menubar__button" @click="commands.horizontal_rule">
            <v-icon> mdi-minus </v-icon>
          </button>

          <button class="menubar__button" @click="commands.undo">
            <v-icon> mdi-undo </v-icon>
          </button>

          <button class="menubar__button" @click="commands.redo">
            <v-icon> mdi-redo </v-icon>
          </button>

          <button
            type="button"
            class="menubar__button"
            @click="
              commands.createTable({
                rowsCount: 3,
                colsCount: 3,
                withHeaderRow: false,
              })
            "
          >
            <v-icon> mdi-table </v-icon>
          </button>

          <div v-if="isActive.table()">
            <button class="menubar__button" @click="commands.deleteTable">
              <v-icon> mdi-table-off </v-icon>
            </button>
            <button class="menubar__button" @click="commands.addColumnBefore">
              <v-icon> mdi-table-column-plus-before </v-icon>
            </button>
            <button class="menubar__button" @click="commands.addColumnAfter">
              <v-icon> mdi-table-column-plus-after </v-icon>
            </button>
            <button class="menubar__button" @click="commands.deleteColumn">
              <v-icon> mdi-table-column-remove </v-icon>
            </button>
            <button class="menubar__button" @click="commands.addRowBefore">
              <v-icon> mdi-table-row-plus-before </v-icon>
            </button>
            <button class="menubar__button" @click="commands.addRowAfter">
              <v-icon> mdi-table-row-plus-after </v-icon>
            </button>
            <button class="menubar__button" @click="commands.deleteRow">
              <v-icon> mdi-table-row-remove </v-icon>
            </button>
            <button class="menubar__button" @click="commands.toggleCellMerge">
              <v-icon> mdi-table-merge-cells </v-icon>
            </button>
          </div>

          <v-dialog v-model="linkMenuIsActive" max-width="400px">
            <v-card class="pa-5">
              <v-card-title class="pa-0">
                {{ $t("common.link") }}
              </v-card-title>
              <v-text-field
                v-model="linkUrl"
                placeholder="https://"
                @keydown.esc="hideLinkMenu"
              />
              <v-btn @click="setLinkUrl(commands.link)" type="button">
                {{ $t("common.save") }}
              </v-btn>
            </v-card>
          </v-dialog>
        </div>
      </editor-menu-bar>

      <editor-content :editor="editor" class="editor__content" />
    </v-card-text>
  </v-card>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";

export default {
  name: "TextEditorModule",
  components: {
    EditorContent,
    EditorMenuBar,
  },

  props: ["content"],

  data() {
    return {
      noReload: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: false,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
        ],
        onUpdate: ({ getHTML }) => {
          this.htmlData = getHTML();
          this.updateParent();
        },
      }),
      htmlData: "",
      linkUrl: null,
      linkMenuIsActive: false,
    };
  },
  created() {
    this.editor.setContent(this.content);
    this.htmlData = this.content;
  },
  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    this.editor.destroy();
  },

  methods: {
    updateParent() {
      this.noReload = true;
      this.$emit("update-html", this.htmlData);
    },

    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
    },

    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },

    setLinkUrl(command) {
      command({ href: this.linkUrl });
      this.hideLinkMenu();
    },
  },

  watch: {
    content() {
      if (!this.noReload) {
        this.editor.setContent(this.content);
        this.htmlData = this.content;
      }
    },
  },
};
</script>

<style deep>
.menubar__button {
  padding-right: 1px;
  padding-left: 1px;
}

.editor__content {
  padding-top: 3px;
  outline: 1px;
  outline-color: aqua;
}

.editor__content table td,
.editor__content table th {
  min-width: 4em;
  border: 1px solid #ddd;
  padding: 4px 4px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.editor__content pre {
  padding: 0.7rem 1rem;
  border-radius: 5px;
  background-color: rgb(47, 46, 44);
  font-size: 1rem;
}

.v-application code {
  background-color: rgb(47, 46, 44);
  color: white;
}

.selectedCell {
  background: rgba(102, 102, 102, 0.6);
}

.ProseMirror {
  border-top: 2px solid #999;
  padding: 4px;
  min-height: 400px;
}
</style>