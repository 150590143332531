<template>
  <div style="padding: 16px">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-autocomplete
          autocomplete="off"
          :label="$t('camOnline.serverSelect')"
          v-model="server"
          :items="servers"
          item-text="url"
          item-value="camOnlineServerId"
          prepend-inner-icon="mdi-select"
        />
        <cam-online-server-create-dialog />
      </v-row>

      <v-row v-if="server != null">
        <v-autocomplete
          autocomplete="off"
          :label="$t('camOnline.streamSelect')"
          v-model="stream"
          :items="streams"
          item-text="title"
          item-value="streamId"
          prepend-inner-icon="mdi-camera"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CamOnlineServerCreateDialog from "./CamOnlineServerCreateDialog.vue";
export default {
  name: "CamOnlineForm",

  props: {
    meta: { require: true, default: () => [] },
  },

  model: "meta",

  components: {
    CamOnlineServerCreateDialog,
  },

  data() {
    return {
      localServer: null,
      localStream: null,
      streams: [],
    };
  },

  computed: {
    ...mapState("camOnline", ["servers"]),

    localMeta: {
      get() {
        return this.meta;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    server: {
      get() {
        return this.localServer;
      },

      async set(val) {
        this.localServer = val;
        var lm = this.localMeta;
        if (lm == undefined) lm = [];

        var idx = lm.findIndex((e) => e.key === "camonline.server");
        if (idx == -1) lm.push({ key: "camonline.server", value: val });
        else lm[idx].value = val;

        if (val != null) this.streams = await this.getStreams(val);
        this.localMeta = lm;
      },
    },

    stream: {
      get() {
        return this.localStream;
      },

      set(val) {
        this.localStream = val;
        var lm = this.localMeta;
        if (lm == undefined) lm = [];

        var idx = lm.findIndex((e) => e.key === "camonline.stream");
        if (idx == -1) lm.push({ key: "camonline.stream", value: val });
        else lm[idx].value = val;

        this.localMeta = lm;
      },
    },
  },

  methods: {
    ...mapActions("camOnline", ["getServers", "getStreams"]),
  },

  async created() {
    await this.getServers();

    if (this.localMeta == undefined || this.localMeta.length < 0) return;

    var srvFromMeta = this.localMeta.find((d) => d.key === "camonline.server");
    if (srvFromMeta == undefined) return;
    this.localServer = srvFromMeta.value;

    this.streams = await this.getStreams(this.localServer);

    var strmFromMeta = this.localMeta.find((d) => d.key === "camonline.stream");
    if (strmFromMeta == undefined) return;
    this.localStream = strmFromMeta.value;
  },
};
</script>